import React, { Component } from "react";
import { connect } from "react-redux";
import BarcodeImage from "../barcode/barcode";
import axios from "axios";
import {projectAddress, projectLogo, projectTitle, serverLink} from "../../resources/url";
import "./paymentreceipt.css";
import {
    setCourseFeesData,
    setMinAmountAllowData,
    setOtherFeesData, setPaymentHistoryData, setScholarshipData,
    setWalletData
} from "../../actions/setpersonaldetail";


class PaymentReceipt extends Component {
    constructor(props) {
        super(props);

        const url_link = window.location.href;
        const page_id = url_link.split('/');

        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const today = new Date();

        const dateNow = `${days[today.getDay()]}, ${monthNames[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`

        this.state = {
            receiptID: page_id.at(-1),
            printDate: dateNow,
            isLoading: true,
            userData: this.props.userData,
            record: "",
            itemList: [],
            total: 0,
        }

    }

    componentDidMount() {
        this.loadReceiptItems()
        this.getPaymentRecords()
    }

    async loadReceiptItems() {
        const sendData = {
            payment_id: this.state.receiptID,
            student_id: this.state.userData.student_id
        }
        await axios.post(`${serverLink}payment/get_print_payment_history`, sendData, this.state.userData.token)
            .then(response => {
                const data = response.data;
                if (data.message === 'success') {
                    console.log(data)
                    if (data.history.length > 0) {
                        this.setState({
                            record: data.history[0],
                            itemList: data.detail,
                            isLoading: false
                        });
                    } else {
                        window.location.href = '/'
                    }

                } else {
                    window.location.href = '/'
                }
            })
            .catch(error => {
               window.location.href = '/'
            });
    }

    async getPaymentRecords() {
        const sendData = {
            student_id: this.props.userData.student_id,
            course_code: this.props.userData.course_code,
            student_level: this.props.userData.student_level,
            student_semester: this.props.userData.student_semester,
            app_type: this.props.userData.app_type
        }
        await axios.post(`${serverLink}payment/dashboard`, sendData, this.props.userData.token)
            .then(res => {
                if (res.data.message === 'success') {
                    const wallet = res.data.wallet;
                    this.setState({currentWallet: parseInt(wallet)});
                    this.props.setOnWalletDataDetails(parseInt(wallet));
                    this.props.setOnCourseFeeDataDetails(res.data.course_fee);
                    this.props.setOnOtherFeesDataDetails(res.data.other_fee);
                    this.props.setOnScholarshipDataDetails(res.data.scholarship);
                    this.props.setOnPaymentHistoryDataDetails(res.data.payment_history);
                }
            })
            .catch(error => {
                console.log('ERROR FETCHING RECORD', error)
            })
    }

    currencyConverter = (amount) => {
        const formatter = new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: 'NGN',
        });
        return formatter.format(amount)
    }

    printNow = () => {
        window.print();
    }

    formatDate = (date) => {
        if (date !== null) {
            const user_date = new Date(date);
            const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            return `${user_date.getDate()}-${monthNames[user_date.getMonth()]}-${user_date.getFullYear()} ${user_date.getHours()}:${user_date.getMinutes()}:${user_date.getSeconds()}`
        } else {
            return '--'
        }
    };

    render() {
        let count = 0;

        const isDate = function (date) {
            return Date.parse(date)
        }

        return (
            <div>
                {this.state.isLoading
                    ?
                    <div className="js-swiper-preloader swiper-preloader">
                        <div className="spinner-border text-secondary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    <main id="content" role="main">
                        <div className="container">
                            <div className="col-lg-12">
                                <div className="head-main mt-5">
                                    <div className="head-item">
                                        <img src={projectLogo} alt={projectTitle} width={70} height={70} style={{ marginRight: '15px' }} />
                                        <span className="text-center">
                                            <h4>{projectTitle.toUpperCase()}</h4>
                                            <span>{projectAddress}</span><br />
                                            <span>Date: {this.state.printDate}</span>
                                        </span>

                                    </div>
                                </div>
                                <br />
                                <div className="text-center" style={{ marginBottom: '0px' }}><b>{this.state.record.SemesterCode} Payment Ticket</b></div>
                                <div className="text-center">
                                    <BarcodeImage value={this.state.receiptID} height={60} width={2.5} />
                                </div>

                                <div className="item-data mt-5">
                                    <div className="">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>{this.props.userData.app_type === 'student' ? 'Student ID' : 'Application ID'}</th>
                                                    <td className="fw-bold">{this.state.record.StudentID}</td>
                                                </tr>
                                                <tr>
                                                    <th>{this.props.userData.app_type === 'student' ? 'Student Name' : 'Applicant Name'}</th>
                                                    <td className="fw-bold">{this.state.record.StudentName}</td>
                                                </tr>
                                                <tr>
                                                    <th>Course: </th>
                                                    <td className="fw-bold text-capitalize">{this.state.userData.course_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Level: </th>
                                                    <td className="fw-bold">{this.state.record.StudentLevel}</td>
                                                </tr>
                                                <tr>
                                                    <th>Semester: </th>
                                                    <td className="fw-bold"> {this.state.record.StudentSemester}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Total Due: </th>
                                                    <td><b>{this.currencyConverter(this.state.record.TotalExpectedAmount)}</b></td>
                                                </tr>
                                                <tr>
                                                    <th>Amount Paid: </th>
                                                    <td><b>{this.currencyConverter(this.state.record.AmountPaid)}</b></td>
                                                </tr>
                                                <tr>
                                                    <th>Balance: </th>
                                                    <td><b> {this.currencyConverter(parseFloat(this.state.record.OutStandingAmount))}</b></td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Payment: </th>
                                                    <td>
                                                        <b>
                                                            {
                                                                isDate(this.state.record.InsertedDate) ? this.formatDate(this.state.record.InsertedDate) : this.state.record.InsertedDate
                                                            }
                                                        </b>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>

                                <table className="table  table-responsive-sm mt-3">
                                    <thead>
                                        <tr>
                                            <th>S/No</th>
                                            <th>Description</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.itemList &&
                                            this.state.itemList.map((item, key) => {
                                                count++;
                                                return (
                                                    <tr key={key}>
                                                        <td>{count}</td>
                                                        <td>{item.Description}</td>
                                                        <td>{this.currencyConverter(parseFloat(item.Amount))}</td>
                                                    </tr>
                                                )
                                            })}
                                        <tr>
                                            <td className="fw-bold text-center" colSpan={2}>TOTAL: </td>
                                            <td className="fw-bold" >{this.currencyConverter(this.state.record.TotalExpectedAmount)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <button id="printPageButton" onClick={this.printNow} className="btn btn-secondary">Print <i className="bi-printer" /></button>
                        </div>
                    </main>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.loginDetails
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnWalletDataDetails: (p) => {
            dispatch(setWalletData(p));
        },
        setOnCourseFeeDataDetails: (p) => {
            dispatch(setCourseFeesData(p));
        },
        setOnOtherFeesDataDetails: (p) => {
            dispatch(setOtherFeesData(p));
        },
        setOnMinAmountAllowDataDetails: (p) => {
            dispatch(setMinAmountAllowData(p));
        },
        setOnScholarshipDataDetails: (p) => {
            dispatch(setScholarshipData(p));
        },
        setOnPaymentHistoryDataDetails: (p) => {
            dispatch(setPaymentHistoryData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentReceipt);

import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import {
    loginDetailsReducer,
    walletReducer,
    courseFeesReducer,
    otherFeesReducer,
    minAmountReducer,
    scholarshipReducer, paymentHistoryReducer
} from "./DataReducer";
import storage from "redux-persist/lib/storage";
import { shortCode } from "../resources/url";

const rootReducer = combineReducers({
    loginDetails: loginDetailsReducer,
    walletData: walletReducer,
    courseFeeData: courseFeesReducer,
    otherFeeData: otherFeesReducer,
    minAmountData: minAmountReducer,
    scholarshipData: scholarshipReducer,
    paymentHistoryData: paymentHistoryReducer
});

const persistConfig = {
    key: shortCode,
    storage,
};

export default persistReducer(persistConfig, rootReducer);

import { shortCode } from '../resources/url';
export const loginDetailsReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_LOGIN_DETAILS`:
            return action.payload;

        default:
            return state;
    }
};

export const walletReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_WALLET_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const courseFeesReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_COURSE_FEES_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const otherFeesReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_OTHER_FEES_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const minAmountReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_MIN_AMOUNT_ALLOW_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const scholarshipReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_SCHOLARSHIP_DATA`:
            return action.payload;

        default:
            return state;
    }
};

export const paymentHistoryReducer = (state = "", action) => {
    switch (action.type) {
        case `SET_${shortCode}_PAYMENT_HISTORY_DATA`:
            return action.payload;

        default:
            return state;
    }
};

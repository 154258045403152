const status = "Live";
export const serverLink =
    status === "Dev"
        ? "http://localhost:4480/"
        // : "https://smartsourcingapi.com:5005/"; //COSMOPOLITAN
        : "https://smartsourcingapi.com:5003/"; //AUM

export const serverStatus = status;
export const shortCode = "AUMPAY"
export const projectTitle = "Al-Ansar University Maiduguri"
export const projectLogo = "https://aum.edu.ng/logo.png"
export const projectAddress = "Maiduguri, Borno State Nigeria"
export const payStackLive = "pk_live_5e3596837a61d6eedd7a5e630d642e14cca3cd63";
export const payStackTest = "pk_test_26837305b305e02f0057202a98ed91393b53cbf8";

// export const shortCode = "COSMOPAY"
// export const projectTitle = "Cosmopolitan University"
// export const projectLogo = "https://cosmopolitan.edu.ng/logo.png"
// export const projectAddress = "Plot 432, Yakubu J. Pam Street, Opposite National Hospital, Central Business District, Abuja"
// export const payStackLive = "pk_live_990bba9b761c1dd651df378da7659028378b7ef4";
// export const payStackTest = "pk_test_63ee8a4297ca0b8329bcd6283761adc8622bbc70";
